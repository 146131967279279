<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.education-partner.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.partner.instructions.header') }}</p>
          <div v-html="$t('message.partner.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education-partner.show.header') }}</p>
          <div class="columns">
           <div class="column is-half">
               <b-field :label="$t('message.education-partner.show.name')">
                 {{ educationPartner.name }}
              </b-field>
              <!--b-field :label="$t('message.education-partner.show.description')">
                {{ educationPartner.description }}
              </b-field-->
              <b-field :label="$t('message.education-partner.show.email')">
                {{ educationPartner.email }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.mobile')">
                {{ educationPartner.mobile }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.address')">
                {{ educationPartner.address }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.zip')">
                {{ educationPartner.zip }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.city')">
                {{ educationPartner.city }}
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('message.education-partner.show.vat')">
                {{ educationPartner.vat }}
              </b-field>
              <b-field :label="$t('message.education-partner.show.www')">
                {{ educationPartner.www }}
              </b-field>
             </div>
            </div>

            <b-button
              :loading="false"
              :label="$t('message.education-partner.edit.button')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="edit" />

        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        edit() {
          this.$router.push('/muokkaa-kumppani/' + this.educationPartner.id)
        },
    },
    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        educationPartner() {
          var id = this.user['education_partner_id']
          return this.$store.getters['getPartners'].find(p => p.id == id)
        },
    },
    mounted() {
       this.$store.dispatch("fetchPartners");
    }
}
</script>